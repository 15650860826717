<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.avatar"
            :text="avatarText(userData.user.hoten)"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-1">
                <b-badge
                  variant="info"
                  class="badge-glow"
                >
                  {{ userData.user.ma_dai_ly }}
                </b-badge>
              </h4>
              <h4 class="mb-0">
                {{ userData.user.hoten }}
              </h4>
              <span class="card-text">{{ userData.user.email }}</span>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userData.user.doanh_thu === '' ? 0 : userData.user.doanh_thu }}
              </h5>
              <small>Doanh thu</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="UsersIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userData.user.so_luong_khach }}
              </h5>
              <small>Khách hàng</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-info"
              rounded
            >
              <feather-icon
                icon="Share2Icon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userData.user.so_luong_dai_ly }}
              </h5>
              <small>Đại lý</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Tên đăng nhập</span>
            </th>
            <td class="pb-50">
              {{ userData.user.username }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Trạng thái</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.user.status }}
            </td>
          </tr>
          <!--          <tr>-->
          <!--            <th class="pb-50">-->
          <!--              <feather-icon-->
          <!--                icon="StarIcon"-->
          <!--                class="mr-75"-->
          <!--              />-->
          <!--              <span class="font-weight-bold">Điện thoại</span>-->
          <!--            </th>-->
          <!--            <td class="pb-50 text-capitalize">-->
          <!--              {{ userData.user.dien_thoai }}-->
          <!--            </td>-->
          <!--          </tr>-->
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Tỉnh/thành phố</span>
            </th>
            <td class="pb-50">
              {{ userData.user.khu_vuc }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Điện thoại</span>
            </th>
            <td>
              {{ userData.user.dien_thoai }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BRow, BCol, BBadge,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BCard, BRow, BCol, BAvatar, BBadge,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
}
</script>

<style>

</style>
